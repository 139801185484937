/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 40px; /* 增加左右留白 */
}

h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center; /* 居中标题 */
}

p {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  text-align: center; /* 居中文本 */
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-icon {
  margin-right: 10px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.table-container {
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 8px; /* 方形带钝角 */
  background-color: #009879; /* 修改背景颜色 */
  cursor: pointer;
  font-size: 1em;
  color: white;
  transition: background-color 0.3s ease;
}

button.active {
  background-color: #006f5b; /* 激活状态颜色 */
}

button:hover {
  background-color: #007f6c; /* 悬停状态颜色 */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

th, td {
  padding: 12px 15px;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

th {
  cursor: pointer;
  position: relative;
}

th span {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px; /* 减小移动设备上的左右留白 */
  }

  table {
    font-size: 0.8em;
  }

  th, td {
    padding: 8px 10px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .search-input {
    font-size: 0.9em;
    padding: 8px;
  }
}


/* App.css */

footer {
  font-style: italic;
  color: #808080; /* 浅灰色 */
  text-align: center;
  padding: 20px 0;
  background-color: #f9f9f9; /* 背景颜色可以根据需要调整 */
}

footer a {
  color: #009879; /* 链接颜色 */
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
